import React from 'react'

function HistoryArea() {
  return (
    <>
     <section className="history-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>History</span>
              <h2>Sync Scrum</h2>
            </div>
          </div>
          <div className="single-history">
            <div className="history wow animate fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img src={process.env.PUBLIC_URL + '/img/timeline-1.jpg'} alt="history-img" />
              </div>
            </div>
            <div className="history wow animate fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2020</span>
                  <h4>Inception and Vision</h4>
                  <p> Founded by visionary entrepreneurs, our journey began with a clear vision to revolutionize the digital landscape. We recognized the rapid pace of technological change and set out to empower businesses with innovative solutions that would position them for success in the digital age. From the very start, we set out to empower businesses with innovative solutions.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-history">
            <div className="history wow animate fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2021</span>
                  <h4>Pioneering Digital Transformation</h4>
                  <p>The year 2021 marked a pivotal point in our history. We set out on a mission to lead businesses towards digital transformation. Our team of experts started offering tailored services, including AI development, UI/UX design, cloud migrations, web development, and enterprise software modernization, which allowed us to deliver transformative solutions to our clients.</p>
                </div>
              </div>
            </div>
            <div className="history wow animate fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img src={process.env.PUBLIC_URL + '/img/timeline-2.jpg'} alt="history-img" />
              </div>
            </div>
          </div>
          <div className="single-history">
            <div className="history wow animate fadeInLeft" data-wow-delay="400ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img src={process.env.PUBLIC_URL + '/img/timeline-3.jpg'} alt="history-img" />
              </div>
            </div>
            <div className="history wow animate fadeInRight" data-wow-delay="400ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2022</span>
                  <h4>Expanding Our Horizons</h4>
                  <p>In 2022, we expanded our range of services to encompass generative AI development, custom LLM development, and ChatGPT application development. These new offerings allowed us to harness the power of artificial intelligence, automation, and natural language processing, creating intelligent solutions that set us apart in the industry. This expansion allowed us to become a holistic technology partner for our clients.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-history">
            <div className="history wow animate fadeInLeft" data-wow-delay="500ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2023</span>
                  <h4>A Year of Achievements</h4>
                  <p>As we reached 2023, we celebrated a year of significant achievements. Our commitment to excellence, innovation, and client-centric services led us to grow our portfolio with cloud migration, single-page application (SPA) development, progressive web app (PWA) development, staff augmentation, and MVP development services.</p>
                </div>
              </div>
            </div>
            <div className="history wow animate fadeInRight" data-wow-delay="500ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img src={process.env.PUBLIC_URL + '/img/timeline-4.jpg'} alt="history-img" />
              </div>
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default HistoryArea