import React from "react";
import ProjectCardFilter from "./ProjectCardFilter";

function ProjectWrap2(props) {
  return (
    <>
      {/* <div className="project-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title white">
              {/* <span>Case Study</span> */}
              {/* <h2 style={{ color: "#75dab4" }}>Our Projects</h2>
              <div className="layout2">
                <h5 style={{ color: "white", fontWeight: "initial" }}>
                  ​​We strive to help our clients unlock their full potential
                  // through transformative solutions and strategic insights. */} 
                {/* </h5>
              </div>
            </div>
          </div>
          <ProjectCardFilter />
        </div>
      </div> */}
    </>
  );
}

export default ProjectWrap2;
