import React from "react";

function WhyChooseUs() {
  return (
    <>
      <section
        className="why-choose sec-mar wow animate fadeIn"
        data-wow-delay="200ms"
        data-wow-duration="1500ms"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="why-choose-left">
                {/* <div className="choose-banner1">
                  <img
                    src={process.env.PUBLIC_URL + "/img/why-1.jpg"}
                    alt="why-choose images"
                  />
                </div> */}
                <div className="choose-banner2" style={{ borderRadius: "8px" }}>
                  <img
                    src={process.env.PUBLIC_URL + "/img/why-2.jpg"}
                    alt="why-choose images"
                  />
                  {/* <img
                    src={process.env.PUBLIC_URL + "/img/why-3.jpg"}
                    alt="why-choose images"
                  /> */}
                </div>
                {/* <div className="years">
                  <h5></h5>
                  <span>Years</span>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-right">
                <div className="sec-title layout2">
                  <span>Why Choose</span>
                  <h2>Your success is our success</h2>
                </div>
                <div className="counter-boxes">
                  <div className="count-box">
                    <span className="counter">100</span>
                    <sup>+</sup>
                    <h5>Project Completed</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">100</span>
                    <sup>+</sup>
                    <h5>Satisfied Clients</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">60</span>
                    <sup>+</sup>
                    <h5>Expert Teams</h5>
                  </div>
                </div>
                <p>
                  At Scrum Logics, we are more than just a tech company, we're a
                  dynamic team of agile enthusiasts and logical thinkers on a
                  mission to empower your digital journey. Our name, Scrum
                  Logics, embodies the perfect blend of agility and structured
                  thinking, and it's at the heart of everything we do. We
                  envision a world where innovative technology solutions
                  seamlessly integrate into businesses, elevating efficiency,
                  productivity, and user experiences. With a strong foundation
                  in Scrum methodologies and logical problem-solving, we're here
                  to make that vision a reality.
                </p>
                <div
                  className="buttons-group"
                  // style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Logical Thinking</span>
                  <span>Clean Code Development</span>
                  <span>Client Centric</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhyChooseUs;
