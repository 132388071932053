import React from "react";
import { Link } from "react-router-dom";
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  Navigation,
  EffectFade,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Autoplay, EffectFade, Pagination]);
function Testimonial() {
  const testimonialSlider = {
    loop: true,
    slidesPerView: 2,
    centeredSlides: true,
    duration: 5000,
    speed: 1000,
    autoplay: {
      delay: 3500,
      isableOnInteraction: true,
    },
    spaceBetween: 30,
    slideToClickedSlide: true,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 2,
      },
      1400: {
        slidesPerView: 2,
      },
      1800: {
        slidesPerView: 2,
      },
    },
  };
  return (
    <>
      <section className="testimonial-area">
        <div className="container-fluid p-0">
          <div className="title-wrap">
            <div className="sec-title white">
              {/* <span>Testimonial</span> */}
              <h2 style={{ color: "#75dab4" }}> What Client Say About Us</h2>
              {/* <p>Curabitur sed facilisis erat. Vestibulum pharetra eros eget fringilla porttitor. on Duis a orci nunc. Suspendisse ac convallis sapien, quis commodo libero.</p> */}
            </div>
          </div>
          <Swiper {...testimonialSlider} className="swiper testimonial-slider">
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Eric Sierra</h5>
                  <span style={{ color: "#75dab4" }}>
                    CEO ( The Real Fitness )
                  </span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                    I am thrilled with Sync Scrum! They developed our mobile app
                    to perfection. Their expertise, quality, and on-time
                    delivery are truly impressive. It's been a fantastic
                    experience. They go the extra mile to resolve any issues
                    promptly.
                  </p>
                  <div className="reviewer">
                    <img
                      src={process.env.PUBLIC_URL + "/img/client1.jpg"}
                      alt="testimonial-img"
                      style={{ height: "80px", width: "84px" }}
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Dan Brown</h5>
                  <span style={{ color: "#75dab4" }}>
                    Owner ( Property Edits )
                  </span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                    Team SYNC SCRUM promptly, comprehended the project, and
                    collaborated effectively. These Guys Knocked It Out Of The
                    Park. I check with SCRUM first to take care of it! Thank you
                    so much for the hard work!
                  </p>
                  <div className="reviewer">
                    <img
                      src={process.env.PUBLIC_URL + "/img/client2.jpg"}
                      alt="testimonial-img"
                      style={{ height: "80px", width: "auto" }}
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Terence Hill</h5>
                  <span style={{ color: "#75dab4" }}>
                    Owner ( Model Shred )
                  </span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                    I have worked with SYNC SCRUM on multiple projects the past
                    two years and am always very pleased with the quality of
                    work, the speed, and most importantly, communication and
                    transparency.
                  </p>
                  <div className="reviewer">
                    <img
                      src={process.env.PUBLIC_URL + "/img/client3.jpg"}
                      alt="testimonial-img"
                      style={{ height: "80px", width: "auto" }}
                    />
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>
    </>
  );
}

export default Testimonial;
