const data = [
  {
    id: 1,
    title: "Software",
    image: "img/project/Datasafe.png",
    category: "developing",
    details: "Desktop Mockup",
  },
  {
    id: 2,
    title: "Template",
    image: "img/project/Bitans.png",
    category: "web",
    details: "Creative Agency",
  },
  {
    id: 3,
    title: "App",
    image: "img/project/Dudda.png",
    category: "App",
    details: "Mobile Crypto Wallet",
  },
  {
    id: 4,
    title: "UI Kit",
    image: "img/project/fixlePay.png",
    category: "UI",
    details: "E-Shop Ecommerce",
  },
  {
    id: 5,
    title: "Graphic",
    image: "img/project/Itoka.png",
    category: "Graphic",
    details: "Art Deco Cocktails",
  },
  {
    id: 6,
    title: "3D Design",
    image: "img/project/mended.jpg",
    category: "3D Design",
    details: "Low Poly Base Mesh",
  },
  // {
  //   id: 7,
  //   title: "3D Design",
  //   image: "img/project/project1 Pistas.jpg",
  //   category: "Graphic",
  //   details: "Low Poly Base Mesh",
  // },
  // {
  //     id:8,
  //     title:"3D Design",
  //     image:"img/project/project-1.jpg",
  //     category: "Graphic",
  //     details:"Low Poly Base Mesh",
  // },
  // {
  //     id:9,
  //     title:"UI Kit",
  //     image:"img/project/project-2.jpg",
  //     category: "UI",
  //     details:"Low Poly Base Mesh",
  // },
  // {
  //     id:10,
  //     title:"UI Kit",
  //     image:"img/project/project-4.jpg",
  //     category: "UI",
  //     details:"Low Poly Base Mesh",
  // },
  // {
  //     id:11,
  //     title:"App",
  //     image:"img/project/project-3.jpg",
  //     category: "App",
  //     details:"Mobile Crypto Wallet",
  // },
  // {
  //     id:12,
  //     title:"App",
  //     image:"img/project/project-1.jpg",
  //     category: "App",
  //     details:"Mobile Crypto Wallet",
  // },
];

export default data;
