import React from "react";
function FaqWrap() {
  return (
    <section className="faqs-area sec-mar-top">
    <div className="container" style={{ display: "flex", justifyContent: "center" }}>
      <div className="row">
        <div className="col-lg-12">
          <div className="faqs-tabs">
            <div className="faqs" id="progress-tab">
              <h4>Progress</h4>
              <div className="accordion" id="accordionOne">
                <div className="accordion-item">
                  <span className="accordion-header" id="heading1">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                      01. How does the software development process work at your company?
                    </button>
                  </span>
                  <div id="collapse1" className="accordion-collapse collapse" aria-labelledby="heading1"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Our software development process follows a systematic approach, from initial planning to deployment. We
                      prioritize collaboration, testing, and quality assurance to ensure successful outcomes.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <span className="accordion-header" id="heading2">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                      02. How do you ensure the security of software during development?
                    </button>
                  </span>
                  <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Security is a top priority. We implement best practices, conduct regular security assessments, and
                      stay updated on the latest security threats and solutions to safeguard your software.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <span className="accordion-header" id="heading3">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                      03. What is your approach to handling project delays and setbacks in software development?
                    </button>
                  </span>
                  <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      While we aim to deliver projects on schedule, we acknowledge that setbacks can occur. Our approach
                      involves transparent communication, assessing the root causes, and adjusting the plan as necessary to
                      minimize delays.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <span className="accordion-header" id="heading4">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                      04. How do you ensure the quality of software during development?
                    </button>
                  </span>
                  <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Quality assurance is integral to our process. We
                      employ rigorous testing, code reviews, and continuous
                      integration to maintain high standards of software
                      quality
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <span className="accordion-header" id="heading5">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                      05. Can I request changes or updates to the software during development?
                    </button>
                  </span>
                  <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Yes, we encourage client involvement. You can request
                      changes or updates during the development process, and
                      we will assess the impact on the project timeline and budget.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <span className="accordion-header" id="heading6">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                      06. What steps do you take to keep clients informed about the progress of their software development
                      project?
                    </button>
                  </span>
                  <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We provide regular updates, status reports, and meetings to keep you informed about the progress of
                      your project. Transparency and communication are key to our process
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="faqs" id="payment-tab">*/}
            {/*  <h4>Payment</h4>*/}
            {/*  <div className="accordion" id="accordionTwo">*/}
            {/*    <div className="accordion-item">*/}
            {/*      <span className="accordion-header" id="heading7">*/}
            {/*        <button*/}
            {/*          className="accordion-button collapsed"*/}
            {/*          type="button"*/}
            {/*          data-bs-toggle="collapse"*/}
            {/*          data-bs-target="#collapse7"*/}
            {/*          aria-expanded="false"*/}
            {/*          aria-controls="collapse7"*/}
            {/*        >*/}
            {/*          01. Aliquam ligula nunc, vehicula at est quis, aliquet*/}
            {/*          facilisis erat?*/}
            {/*        </button>*/}
            {/*      </span>*/}
            {/*      <div*/}
            {/*        id="collapse7"*/}
            {/*        className="accordion-collapse collapse"*/}
            {/*        aria-labelledby="heading7"*/}
            {/*        data-bs-parent="#accordionExample"*/}
            {/*      >*/}
            {/*        <div className="accordion-body">*/}
            {/*          Aptent taciti sociosqu ad litora torquent per conubia*/}
            {/*          nostra, per inceptos only Integer purus odiopl*/}
            {/*          himenaeos. Praesent nec neque at dolor venenatis*/}
            {/*          consectetur eu quis ex. the Donec lacinia andal*/}
            {/*          placerat felis non aliquam.Mauris nec justo vitae ante*/}
            {/*          auctor tol euismod sit amet non ipsum. sovo Praesent*/}
            {/*          commodo at massa eget suscipit. Utani vitae enim*/}
            {/*          velit.*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="accordion-item">*/}
            {/*      <span className="accordion-header" id="heading8">*/}
            {/*        <button*/}
            {/*          className="accordion-button collapsed"*/}
            {/*          type="button"*/}
            {/*          data-bs-toggle="collapse"*/}
            {/*          data-bs-target="#collapse8"*/}
            {/*          aria-expanded="false"*/}
            {/*          aria-controls="collapse8"*/}
            {/*        >*/}
            {/*          02. In hac habitasse platea dictumst Cras leo erat,*/}
            {/*          laoreet vitae dictum?*/}
            {/*        </button>*/}
            {/*      </span>*/}
            {/*      <div*/}
            {/*        id="collapse8"*/}
            {/*        className="accordion-collapse collapse"*/}
            {/*        aria-labelledby="heading8"*/}
            {/*        data-bs-parent="#accordionExample"*/}
            {/*      >*/}
            {/*        <div className="accordion-body">*/}
            {/*          Aptent taciti sociosqu ad litora torquent per conubia*/}
            {/*          nostra, per inceptos only Integer purus odiopl*/}
            {/*          himenaeos. Praesent nec neque at dolor venenatis*/}
            {/*          consectetur eu quis ex. the Donec lacinia andal*/}
            {/*          placerat felis non aliquam.Mauris nec justo vitae ante*/}
            {/*          auctor tol euismod sit amet non ipsum. sovo Praesent*/}
            {/*          commodo at massa eget suscipit. Utani vitae enim*/}
            {/*          velit.*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="accordion-item">*/}
            {/*      <span className="accordion-header" id="heading9">*/}
            {/*        <button*/}
            {/*          className="accordion-button collapsed"*/}
            {/*          type="button"*/}
            {/*          data-bs-toggle="collapse"*/}
            {/*          data-bs-target="#collapse9"*/}
            {/*          aria-expanded="false"*/}
            {/*          aria-controls="collapse9"*/}
            {/*        >*/}
            {/*          03. ornare iaculis tellus. Phasellus venenatis vel*/}
            {/*          ligula vitae dictum?*/}
            {/*        </button>*/}
            {/*      </span>*/}
            {/*      <div*/}
            {/*        id="collapse9"*/}
            {/*        className="accordion-collapse collapse"*/}
            {/*        aria-labelledby="heading9"*/}
            {/*        data-bs-parent="#accordionExample"*/}
            {/*      >*/}
            {/*        <div className="accordion-body">*/}
            {/*          Aptent taciti sociosqu ad litora torquent per conubia*/}
            {/*          nostra, per inceptos only Integer purus odiopl*/}
            {/*          himenaeos. Praesent nec neque at dolor venenatis*/}
            {/*          consectetur eu quis ex. the Donec lacinia andal*/}
            {/*          placerat felis non aliquam.Mauris nec justo vitae ante*/}
            {/*          auctor tol euismod sit amet non ipsum. sovo Praesent*/}
            {/*          commodo at massa eget suscipit. Utani vitae enim*/}
            {/*          velit.*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="accordion-item">*/}
            {/*      <span className="accordion-header" id="heading10">*/}
            {/*        <button*/}
            {/*          className="accordion-button collapsed"*/}
            {/*          type="button"*/}
            {/*          data-bs-toggle="collapse"*/}
            {/*          data-bs-target="#collapse10"*/}
            {/*          aria-expanded="false"*/}
            {/*          aria-controls="collapse10"*/}
            {/*        >*/}
            {/*          04. Phasellus a placerat eros In hac habitasse platea*/}
            {/*          dictumst Fusce ac?*/}
            {/*        </button>*/}
            {/*      </span>*/}
            {/*      <div*/}
            {/*        id="collapse10"*/}
            {/*        className="accordion-collapse collapse"*/}
            {/*        aria-labelledby="heading10"*/}
            {/*        data-bs-parent="#accordionExample"*/}
            {/*      >*/}
            {/*        <div className="accordion-body">*/}
            {/*          Aptent taciti sociosqu ad litora torquent per conubia*/}
            {/*          nostra, per inceptos only Integer purus odiopl*/}
            {/*          himenaeos. Praesent nec neque at dolor venenatis*/}
            {/*          consectetur eu quis ex. the Donec lacinia andal*/}
            {/*          placerat felis non aliquam.Mauris nec justo vitae ante*/}
            {/*          auctor tol euismod sit amet non ipsum. sovo Praesent*/}
            {/*          commodo at massa eget suscipit. Utani vitae enim*/}
            {/*          velit.*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="accordion-item">*/}
            {/*      <span className="accordion-header" id="heading11">*/}
            {/*        <button*/}
            {/*          className="accordion-button collapsed"*/}
            {/*          type="button"*/}
            {/*          data-bs-toggle="collapse"*/}
            {/*          data-bs-target="#collapse11"*/}
            {/*          aria-expanded="false"*/}
            {/*          aria-controls="collapse11"*/}
            {/*        >*/}
            {/*          05. sagittis risus In porta augue tortor eu*/}
            {/*          condimentum arcu iaculis vel?*/}
            {/*        </button>*/}
            {/*      </span>*/}
            {/*      <div*/}
            {/*        id="collapse11"*/}
            {/*        className="accordion-collapse collapse"*/}
            {/*        aria-labelledby="heading11"*/}
            {/*        data-bs-parent="#accordionExample"*/}
            {/*      >*/}
            {/*        <div className="accordion-body">*/}
            {/*          Aptent taciti sociosqu ad litora torquent per conubia*/}
            {/*          nostra, per inceptos only Integer purus odiopl*/}
            {/*          himenaeos. Praesent nec neque at dolor venenatis*/}
            {/*          consectetur eu quis ex. the Donec lacinia andal*/}
            {/*          placerat felis non aliquam.Mauris nec justo vitae ante*/}
            {/*          auctor tol euismod sit amet non ipsum. sovo Praesent*/}
            {/*          commodo at massa eget suscipit. Utani vitae enim*/}
            {/*          velit.*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="accordion-item">*/}
            {/*      <span className="accordion-header" id="heading12">*/}
            {/*        <button*/}
            {/*          className="accordion-button collapsed"*/}
            {/*          type="button"*/}
            {/*          data-bs-toggle="collapse"*/}
            {/*          data-bs-target="#collapse12"*/}
            {/*          aria-expanded="false"*/}
            {/*          aria-controls="collapse12"*/}
            {/*        >*/}
            {/*          06. tristique suscipit mi vitae rutrum. Ut auctor*/}
            {/*          rutrum fringilla?*/}
            {/*        </button>*/}
            {/*      </span>*/}
            {/*      <div*/}
            {/*        id="collapse12"*/}
            {/*        className="accordion-collapse collapse"*/}
            {/*        aria-labelledby="heading12"*/}
            {/*        data-bs-parent="#accordionExample"*/}
            {/*      >*/}
            {/*        <div className="accordion-body">*/}
            {/*          Aptent taciti sociosqu ad litora torquent per conubia*/}
            {/*          nostra, per inceptos only Integer purus odiopl*/}
            {/*          himenaeos. Praesent nec neque at dolor venenatis*/}
            {/*          consectetur eu quis ex. the Donec lacinia andal*/}
            {/*          placerat felis non aliquam.Mauris nec justo vitae ante*/}
            {/*          auctor tol euismod sit amet non ipsum. sovo Praesent*/}
            {/*          commodo at massa eget suscipit. Utani vitae enim*/}
            {/*          velit.*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="faqs" id="support-tab" style={{ marginBottom: "120px" }}>
              <h4>Support</h4>
              <div className="accordion" id="accordionThree">
                <div className="accordion-item">
                  <span className="accordion-header" id="heading13">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                      01. What happens after the software is developed and deployed?
                    </button>
                  </span>
                  <div id="collapse13" className="accordion-collapse collapse" aria-labelledby="heading13"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      After deployment, we offer ongoing support and maintenance to address any issues, implement updates,
                      and ensure the software continues to meet your evolving needs.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <span className="accordion-header" id="heading14">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
                      02. What type of post-delivery support do you offer?
                    </button>
                  </span>
                  <div id="collapse14" className="accordion-collapse collapse" aria-labelledby="heading14"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We provide ongoing support and maintenance after software delivery. This includes addressing issues,
                      implementing updates, and ensuring the software continues to meet your needs.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <span className="accordion-header" id="heading15">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapse15">
                      03. How do I report issues or request support after the software is delivered?
                    </button>
                  </span>
                  <div id="collapse15" className="accordion-collapse collapse" aria-labelledby="heading15"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      You can report issues or request support by contacting
                      our support team through our designated channels,
                      which include email, phone. We are responsive and will address your concerns promptly.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <span className="accordion-header" id="heading16">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
                      04. Can I extend or modify the post-delivery support plan if my needs change?
                    </button>
                  </span>
                  <div id="collapse16" className="accordion-collapse collapse" aria-labelledby="heading16"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Yes, you can discuss and modify your post-delivery support plan to align with changing needs. We are
                      flexible and strive to accommodate your evolving requirements.
                    </div>
                  </div>
                </div>
                {/*<div className="accordion-item">*/}
                {/*  <span className="accordion-header" id="heading17">*/}
                {/*    <button*/}
                {/*      className="accordion-button collapsed"*/}
                {/*      type="button"*/}
                {/*      data-bs-toggle="collapse"*/}
                {/*      data-bs-target="#collapse17"*/}
                {/*      aria-expanded="false"*/}
                {/*      aria-controls="collapse17"*/}
                {/*    >*/}
                {/*      05. What is the process for recovering from unexpected outages or critical issues post-deployment?*/}
                {/*    </button>*/}
                {/*  </span>*/}
                {/*  <div*/}
                {/*    id="collapse17"*/}
                {/*    className="accordion-collapse collapse"*/}
                {/*    aria-labelledby="heading17"*/}
                {/*    data-bs-parent="#accordionExample"*/}
                {/*  >*/}
                {/*    <div className="accordion-body">*/}
                {/*        In the event of unexpected outages or critical issues, we have a*/}
                {/*        dedicated support team on standby. We work swiftly to diagnose the*/}
                {/*        issue, apply fixes, and restore service to minimize downtime and*/}
                {/*        impact on your operations.*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="accordion-item">
                  <span className="accordion-header" id="heading18">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse18" aria-expanded="false" aria-controls="collapse18">
                      06. How do you handle user training and onboarding for new features or updates after software delivery?
                    </button>
                  </span>
                  <div id="collapse18" className="accordion-collapse collapse" aria-labelledby="heading18"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We provide user training and onboarding services to help your team effectively utilize new features and
                      updates. Our training programs are tailored to your
                      needs and are designed to ensure a smooth transition and adoption of new functionalities.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}
export default FaqWrap;
