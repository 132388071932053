import React from "react";
import { Link } from "react-router-dom";
import ServiceDetailsItems from "./ServiceDetailsItems";
import { useRef } from "react";

function ServiceDetailsWrap() {
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const section6Ref = useRef(null);
  const section7Ref = useRef(null);
  const section8Ref = useRef(null);
  const section9Ref = useRef(null);
  const section10Ref = useRef(null);
  const section11Ref = useRef(null);
  const section12Ref = useRef(null);
  const scrollToSection = (ref) =>
    ref.current.scrollIntoView({ behavior: "smooth" });
  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img src={process.env.PUBLIC_URL + "/img/blog/blog-thumnail.jpg"} alt="images service" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-widget">
                <h4>Category</h4>
                <ul className="category">
                  <li>
                    <Link to="#" onClick={() => scrollToSection(section1Ref)}>
                      Custom Software Development
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => scrollToSection(section2Ref)}>
                      Software Consulting
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => scrollToSection(section3Ref)}>
                      Generative AI Application Development
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => scrollToSection(section4Ref)}>
                      ChatGPT Application Development
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => scrollToSection(section5Ref)}>
                      MVP Development
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => scrollToSection(section6Ref)}>
                      UI/UX Design
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => scrollToSection(section7Ref)}>
                      Resource Augmentation
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => scrollToSection(section8Ref)}>
                      Big Data Solutions
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => scrollToSection(section9Ref)}>
                      Cloud Migartion
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => scrollToSection(section10Ref)}>
                      SAAS Development
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => scrollToSection(section11Ref)}>
                      Mobile Application Development
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => scrollToSection(section12Ref)}>
                      Proof of Concept Development
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <ServiceDetailsItems section1Ref={section1Ref} section2Ref={section2Ref} section3Ref={section3Ref}
            section4Ref={section4Ref} section5Ref={section5Ref} section6Ref={section6Ref} section7Ref={section7Ref}
            section8Ref={section8Ref} section9Ref={section9Ref} section10Ref={section10Ref} section11Ref={section11Ref}
            section12Ref={section12Ref} />
        </div>
      </div>
    </>
  );
}
export default ServiceDetailsWrap;
