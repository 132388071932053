import React from "react";
import { Link } from "react-router-dom";
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
SwiperCore.use([Navigation, Autoplay]);

function ServiceArea(props) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const serviceSlider = {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    slidesPerView: 3,
    spaceBetween: 30,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };
  return (
    <>
      <section className={`${"services-area sec-mar"} ${props.layoutClass}`}>
        <div className="container">
          <div
            className="title-wrap wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className={`${"sec-title"} ${props.white}`}>
              {/* <span>Our Services</span> */}
              <h2 style={{ color: "#75dab4" }}>Services</h2>
              <p>
                At Sync Scrum we specialize in a range of innovative and
                cutting-edge services to empower your business in the digital
                age. Our dedicated team of experts is committed to transforming
                your vision into reality. Explore our diverse services and
                discover how we can help your business thrive.
              </p>
            </div>
          </div>
          <Swiper {...serviceSlider} className="swiper services-slider">
            <div className="swiper-wrapper">
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/digital.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>Digital Transformation</h4>
                  <p>Fuel growth with our digital transformation solutions.</p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/AI.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>Generative AI </h4>
                  <p>
                    We pioneer new possibilities, streamline business workflows.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/ChatGpt.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>ChatGPT Development</h4>
                  <p>Info retrieval, and engagement, streamline processes.</p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/UI.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>UI/UX Design</h4>
                  <p>
                    Specialized in creating smooth UI/UX for better experience.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/saas.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>SaaS Development </h4>
                  <p>
                    Specialized in SaaS apps, hosting, APIs, data, security,
                    quality, speed
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/Web.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>Web Development</h4>
                  <p>
                    Expert web solutions: performance, security, scalability,
                    and aesthetics
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/mobile.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>Mobile Applications</h4>
                  <p>Custom mobile apps for iOS & Android engagement.</p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/ent.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>Enterprise Softwares</h4>
                  <p>Modernize software for efficiency and adaptability.</p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/bd.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>Big Data Solutions </h4>
                  <p>Big data expertise for insights and value.</p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/cloud.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>Cloud Migration</h4>
                  <p>
                    Cloud migration for scalability, flexibility,
                    cost-effectiveness.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              {/* <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>SPA Development</h4>
                  <p>Lightning-fast SPAs for user satisfaction.</p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide> */}
              {/* <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>PWA Development</h4>
                  <p>Enhance web-mobile synergy with PWAs for all devices.</p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide> */}
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/staff.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>Staff Augmentation</h4>
                  <p>
                    Augment your team with expert skills, on time and budget.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              {/* <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/digital.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>MVP Development</h4>
                  <p>Rapid MVP dev: Idea to market, user feedback, success.</p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide> */}
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>
    </>
  );
}

export default ServiceArea;
