import React from "react";

function ServiceDetailsItems({
  section1Ref,

  section2Ref,
  section3Ref,
  section4Ref,
  section5Ref,
  section6Ref,
  section7Ref,
  section8Ref,
  section9Ref,
  section10Ref,
  section11Ref,
  section12Ref,
}) {
  return (
    <>
      <div className="service-items sec-mar-top">
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details" ref={section1Ref}>
                <h4>
                  <span>01</span>Custom Software Development
                </h4>
                <p>
                  Sync Scrum is your expert partner in creating bespoke software
                  solutions tailored to your specific business needs. Our custom
                  software development approach is built on innovation and
                  efficiency, delivering applications that streamline
                  operations, enhance productivity, and fuel growth. With a
                  dedicated focus on your success, we not only develop
                  cutting-edge software but also provide ongoing support to
                  ensure it adapts to your evolving requirements, keeping your
                  business at the forefront of the digital landscape. What sets
                  us apart is our commitment to delivering software that evolves
                  with your business. We understand that your needs change, so
                  our solutions are designed to scale, making them a long-term
                  investment that ensures your organization remains agile and
                  competitive. Collaborate with us to harness the power of
                  custom software and unlock your organization's full potential.
                  Join hands with Sync Scrum to drive efficiency, boost
                  productivity, and maintain a competitive edge in your industry
                  with our customized software solutions.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/thumb-1.png"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6 or-2">
              <div className="single-details-thumb" id="web">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/thumb-22.png"}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details" ref={section2Ref}>
                <h4>
                  <span>02</span>Software Consulting
                </h4>
                <p>
                  Sync Scrum stands as your unwavering partner in navigating the
                  ever-evolving landscape of technology, dedicated to
                  catapulting your business to the forefront of technological
                  advancement. In a world where technology serves as the
                  catalyst for profound business metamorphosis, we emerge as the
                  beacon of insightful, strategic, and customized solutions,
                  firmly grounded in our profound comprehension of the digital
                  ecosystem. Our consultancy transcends mere solutions; it's a
                  commitment to arming you with the knowledge and tools
                  necessary for securing a lasting competitive edge. With us,
                  every consultation is a transformative journey, a voyage of
                  discovery and innovation, characterized by the personalized
                  attention we dedicate to your unique needs. Through
                  collaborative problem-solving and a steadfast dedication to
                  excellence, we aspire to be not just consultants, but your
                  steadfast partners in achieving operational efficiency and
                  business excellence in the digital age. At Sync Scrum, we
                  don't just follow trends; we set them. Join us, and together
                  we'll embark on a path to reshape the future of your business,
                  one technological advancement at a time.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details" ref={section3Ref}>
                <h4>
                  <span>03</span>Generative AI Application Development
                </h4>
                <p>
                  At Sync Scrum, we are dedicated to enabling businesses by
                  harnessing the boundless capabilities of generative AI to
                  meticulously craft adaptive, learning applications that yield
                  exceptional performance and a distinct competitive edge. Our
                  team of AI specialists, data scientists, and developers,
                  comprised of experts in their respective fields, excels in
                  converting intricate challenges into sophisticated AI
                  solutions. We prioritize user experience, operational
                  efficiency, and scalability, ensuring that our creations not
                  only meet but exceed expectations. With a foundation built
                  upon the pillars of creativity, technology, and strategy,
                  every AI application we design is a harmonious blend of
                  innovation, precision, and technical mastery. Our goal is to
                  fashion bespoke solutions that propel your business into the
                  promising future of artificial intelligence.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/thumb-33.png"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 or-2 order-2 order-lg-1">
            <div className="single-details-thumb">
              <img
                src={process.env.PUBLIC_URL + "/img/blog/thumb-44.png"}
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="single-details" ref={section4Ref}>
              <h4>
                <span>04</span>ChatGPT Application Development
              </h4>
              <p>
                Sync Scrum is a pioneer in crafting intelligent and responsive
                chatbot applications, harnessing the formidable capabilities of
                OpenAI's GPT models. Our primary objective is the metamorphosis
                of customer engagement, the enhancement of user experiences, and
                the optimization of business operations. Our chatbot
                applications exemplify the embodiment of precision, meticulously
                customized to cater to your unique requirements. This results in
                personalized interactions, real-time responsiveness, and an
                elevation in customer satisfaction levels that are second to
                none. At Sync Scrum, our team comprises seasoned developers and
                AI experts who are at the vanguard of technological advancements
                and innovations. They channel their expertise to deliver chatbot
                solutions that are not only dependable but also scalable, all
                impeccably tailored to align with your specific business needs.
                When you choose Sync Scrum, you aren't merely enlisting a
                service provider; you are forging a partnership. We are
                unwaveringly dedicated to the cause of elevating your business,
                magnifying customer experiences, and positioning your brand as a
                pioneering force in the realms of digital innovation and
                customer contentment.
              </p>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details" ref={section5Ref}>
                <h4>
                  <span>05</span>MVP Development
                </h4>
                <p>
                  Sync Scrum is dedicated to the seamless transformation of your
                  innovative concepts into streamlined, effective, and
                  value-driven Minimum Viable Products (MVPs). Our tailored
                  approach ensures that each MVP is imbued with innovation,
                  efficiency, and strategic acumen, providing a robust platform
                  to evaluate and enhance your digital solutions in authentic
                  market settings. As a distinguished MVP Development Agency, we
                  take pride in our rich history of employing agile
                  methodologies, state-of-the-art technologies, and user-centric
                  designs to craft MVPs that are technically robust and
                  perfectly attuned to market requisites and customer
                  aspirations. Every project at Sync Scrum embarks on a voyage
                  of collaborative innovation. Here, your ideas are honed,
                  nurtured, and brought to fruition by a dedicated team of adept
                  developers, designers, and strategists committed to the
                  triumphant realization of your project's objectives.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/thumb-55.png"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 or-2 order-2 order-lg-1">
            <div className="single-details-thumb">
              <img
                src={process.env.PUBLIC_URL + "/img/blog/thumb-66.png"}
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="single-details" ref={section6Ref}>
              <h4>
                <span>06</span>UIUX Deisgn
              </h4>
              <p>
                Interdum et malesuada fames ac ante ipsum primis in faucibus.
                Etiam eu nibh elementum, accumsan ona vestibulum ultricies
                tellus. volv Pellentesque vel tu neque ac, aliquet nunc. In eu
                ipsum fringilla, accumsan purus vel, tourbakaniv pellentesque
                risus. Vivamus vehicula nl hendrerit vel tortor. In pharetra
                lectus purus at eros interdum, in dignissim nulla vestibulum.
                Nunc sit amet finibuson felis, ut egestas lacus. Sedan
                pellentesque quis magna eu vestibulum. Olangb pellentesque quis
                magna eu vestibulum. Ut sed commodo neque. Morbi erat nisi,
                vehicula quis faucibus il
              </p>
            </div>
          </div>
        </div>

        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details" ref={section7Ref}>
                <h4>
                  <span>07</span>Resource Augmentation
                </h4>
                <p>
                  At Sync Scrum, we understand that your project's success often
                  hinges on having the right talents at the right time. Our
                  Resource Augmentation services are designed to seamlessly
                  augment your existing team with skilled professionals who
                  bring their expertise and experience to enhance your project's
                  capabilities. Our team of experts is ready to integrate into
                  your project, complement your in-house workforce, and provide
                  an immediate boost to productivity. Whether you require
                  additional developers, designers, or strategists, we have the
                  talent pool to match your specific needs. With our Resource
                  Augmentation services, you can scale your team up or down as
                  required, ensuring maximum flexibility in managing your
                  project's resources. By choosing Sync Scrum for Resource
                  Augmentation, you gain access to a rich talent pool of
                  professionals who are not only highly skilled but also
                  well-versed in the latest industry trends and best practices.
                  This approach allows your project to benefit from fresh
                  insights and a diversified skill set, ultimately leading to
                  more efficient and successful project outcomes. Our commitment
                  to transparency, effective communication, and a seamless
                  integration process makes Sync Scrum the ideal partner for
                  your Resource Augmentation needs. We provide the support and
                  expertise necessary to help you meet your project milestones
                  and deliver exceptional results.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/thumb-77.png"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 or-2 order-2 order-lg-1">
            <div className="single-details-thumb">
              <img
                src={process.env.PUBLIC_URL + "/img/blog/thumb-88.png"}
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="single-details" ref={section8Ref}>
              <h4>
                <span>08</span>Big Data Solutions
              </h4>
              <p>
                At Sync Scrum, we understand that the digital landscape is
                constantly evolving, generating an overwhelming amount of data.
                To navigate this data-rich environment, we offer comprehensive
                Big Data Solutions that unlock valuable insights, drive informed
                decision-making, and empower your business to stay ahead in
                today's data-driven world. Our Big Data Solutions encompass the
                entire data lifecycle, from data collection and storage to
                processing, analysis, and visualization. We utilize the latest
                tools, technologies, and best practices to ensure that your
                organization can leverage its data assets to the fullest. With
                Sync Scrum, you can trust that our Big Data Solutions are
                tailored to meet the unique requirements of your business,
                providing a competitive edge through data-driven decision-making
                and innovation. Choose Sync Scrum for unparalleled expertise in
                Big Data Solutions, and experience the transformative power of
                data like never before.
              </p>
            </div>
          </div>
        </div>
        <div className="service-details-item">
          <div className="row">
            <div className="col-lg-6">
              <div className="single-details" ref={section9Ref}>
                <h4>
                  <span>09</span>Cloud Migration
                </h4>
                <p>
                  Sync Scrum is your trusted partner for Cloud Migration, a
                  pivotal transformation that empowers businesses to enhance
                  efficiency and scalability. Cloud Migration involves the
                  strategic shift of data, applications, and digital assets from
                  on-premises infrastructure or one cloud platform to another,
                  aiming to optimize performance and reduce costs. Sync Scrum
                  offers a wealth of expertise in this domain, with a team of
                  experts well-versed in diverse cloud platforms and migration
                  techniques. We have successfully completed numerous cloud
                  migration projects across various industries. What sets us
                  apart is our commitment to delivering customized solutions
                  that align with your unique business needs. We meticulously
                  plan each migration to ensure minimal disruption, reducing
                  downtime and data loss while focusing on cost efficiency. Data
                  security is paramount to us, and we employ stringent measures
                  to safeguard your data throughout the migration process and
                  beyond. Furthermore, our services enable scalability, allowing
                  your business to adapt and grow as requirements evolve. Our
                  comprehensive cloud migration process includes assessment,
                  planning, execution, testing, and ongoing optimization. Join
                  the cloud with Sync Scrum to unlock the full potential of your
                  business through a successful Cloud Migration journey.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/thumb-99.png"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row">
            <div className="col-lg-6 or-2 order-2 order-lg-1">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/thumb-100.png"}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="single-details" ref={section10Ref}>
                <h4>
                  <span>10</span>SAAS Development
                </h4>
                <p>
                  Sync Scrum pioneers the realm of advanced technology, offering
                  a diverse array of cutting-edge Bespoke Software as a Service
                  (SaaS) applications. With a rich history of experience and an
                  unwavering commitment to staying at the forefront of the
                  latest technologies, we have redefined the landscape of SaaS
                  development. SaaS development involves creating cloud-based
                  applications accessible over the internet, catering to a wide
                  spectrum of industries to simplify processes, enhance
                  productivity, and facilitate effortless scalability. What sets
                  us apart is our innovative approach, where we blend technical
                  expertise with creativity to deliver solutions that not only
                  address your current needs but also future-proof your
                  business. Customization is our hallmark; we take the time to
                  understand your unique requirements, ensuring your SaaS
                  platform aligns seamlessly with your objectives. With a focus
                  on scalability, data security, and ongoing collaboration, we
                  build, test, deploy, and maintain SaaS applications to the
                  highest standards. Experience the future of business with Sync
                  Scrum, where we empower businesses to thrive in today's
                  rapidly evolving digital landscape through innovative and
                  secure Bespoke SaaS solutions.
                </p>
              </div>
            </div>
          </div>
          <div className="service-details-item">
            <div className="row">
              <div className="col-lg-6">
                <div className="single-details" ref={section11Ref}>
                  <h4>
                    <span>11</span>Mobile Application Development
                  </h4>
                  <p>
                    Sync Scrum stands as a prominent name in the realm of
                    Bespoke Mobile Application Development, leveraging the
                    latest cutting-edge technologies such as React Native,
                    Swift, and Flutter. With an extensive track record of
                    crafting tailor-made mobile applications, we are dedicated
                    to bringing your unique vision to life. Our team of skilled
                    developers excels in the art of mobile app creation,
                    ensuring that each project embodies innovation, efficiency,
                    and strategic insight. We pride ourselves on staying at the
                    forefront of technology, enabling us to deliver not just
                    functional, but also user-centric designs that resonate with
                    the ever-evolving market demands. Our collaborative approach
                    transforms your innovative ideas into efficient and
                    value-driven mobile solutions, setting the stage for success
                    in the competitive digital landscape. Sync Scrum is your
                    trusted partner for creating mobile applications that stand
                    out and perform exceptionally in today's fast-paced mobile
                    ecosystem.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-details-thumb">
                  <img
                    src={process.env.PUBLIC_URL + "/img/blog/thumb-111.png"}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginBottom: "56px" }}>
            <div className="col-lg-6 or-2 order-2 order-lg-1">
              <div className="single-details-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/blog/thumb-222.png"}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="single-details" ref={section12Ref}>
                <h4>
                  <span>12</span>Proof of Concept Development
                </h4>
                <p>
                  At Sync Scrum, we specialize in turning your innovative ideas
                  into reality through our PoC Development service. PoC
                  Development is the cornerstone of our portfolio, bridging the
                  gap between your concepts and tangible prototypes. Our PoC
                  serves as a powerful yet scaled-down model that validates the
                  potential of your visionary ideas. We prioritize your vision,
                  tailor our services to match your unique goals, and
                  collaborate closely with you to ensure your PoC reflects your
                  distinct objectives. With a strong track record of successful
                  PoCs across various industries, we navigate the complexities
                  of your idea efficiently, delivering rapid PoC development
                  without compromising quality. A well-executed PoC can be a
                  compelling tool for securing investments and demonstrating the
                  practicality of your idea to stakeholders. Join forces with
                  Sync Scrum to bring your concepts to life and realize their
                  potential.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsItems;
